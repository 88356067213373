import React, {
  Component,
  Fragment } from 'react';
import { Bar, Scatter} from 'react-chartjs-2';


class Graph extends Component {
  constructor(props) {
    super(props);
    const regularParams = [[[-6, -1], [-5, 2]], [[-2, 4], [-2, 5]], [[2, 8], [-3, 2]]]
    const offsetParams = [[[-1, 4], [-1, 5]], [[2, 8], [-4, 2]], [[-7, -2], [-5, 2]]]
    const dataParams = (this.props.type === "regular") ? regularParams : offsetParams;
    this.state = {
      data: {
        labels: ['Scatter'],
        datasets: [
          {
            label: 'Crédito Pronto',
            backgroundColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: 'rgba(75,192,192,0.3)',
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointRadius: 8,
            pointHoverRadius: 8,
            data: this.createData(...dataParams[0])
          },
          {
            label: 'Crédito Ya',
            backgroundColor: 'rgba(212,175,55,1)',
            pointBackgroundColor: 'rgba(212,175,55,0.3)',
            pointHoverBackgroundColor: 'rgba(212,175,55,1)',
            pointRadius: 8,
            pointHoverRadius: 8,
            data: this.createData(...dataParams[1])
          },
          {
            label: 'No Crédito',
            backgroundColor: 'rgba(181,33,60,1)',
            pointBackgroundColor: 'rgba(181,33,60,.3)',
            pointHoverBackgroundColor: 'rgba(181,33,60,1)',
            pointRadius: 8,
            pointHoverRadius: 8,
            data: this.createData(...dataParams[2])
          }
        ]
      },
      options: {
        maintainAspectRatio: true,
        aspectRatio: 1,
        scales: {
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'PC2',
              fontSize: 16
            },
            ticks: {
              min: -10,
              max: 10,
              stepSize: 5
            }
          }],
          xAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'PC1',
              fontSize: 16
            },
            ticks: {
              min: -10,
              max: 10,
              stepSize: 5
            }
          }]
        }
      }
    }
  }

  getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  createData = (xRange, yRange) => {
    const data = [];
    var x_axis = 0;
    var y_axis = 0;
    // calculando el centro de el elipse
    // const elipseVal =[((xRange[0] + xRange[1]) / 2), ((yRange[0] + yRange[1]) / 2),  Math.abs(xRange[1]) - 0.01 , Math.abs(yRange[1])]
    // const elipseVal = [x, y, a, b]
    console.log("a, b values", [(Math.abs(((xRange[0] + xRange[1]) / 2)) - Math.abs(xRange[1])) , (Math.abs(yRange[1]) + Math.abs(((yRange[0] + yRange[1]) / 2)))])
    const elipseVal =[((xRange[0] + xRange[1]) / 2), ((yRange[0] + yRange[1]) / 2),  (Math.abs(((xRange[0] + xRange[1]) / 2)) - Math.abs(xRange[1])) , (Math.abs(yRange[1]) + Math.abs(((yRange[0] + yRange[1]) / 2)))]

    for(var x = 0; x < 500; x++) {
      x_axis = (this.getRandomInt(xRange[0], xRange[1]) + Math.random()).toFixed(2)
      y_axis = (this.getRandomInt(yRange[0], yRange[1]) + Math.random()).toFixed(2)
      // checking if the poin is in the elipse
      // (x-h)^2/a^2 + (y-k)^2/b^2 <= 1
      if ((Math.pow((x_axis - elipseVal[0]), 2) / Math.pow(elipseVal[2], 2)) + (Math.pow((y_axis - elipseVal[1]), 2) / Math.pow(elipseVal[3], 2))  <= 1 ) {
        data.push({
          x: x_axis,
          y: y_axis
        })
      }
    }
    return data;
  }

  render() {
    return (
      <div className="box">
        <Scatter data={ this.state.data } options={ this.state.options } height={null} width={null} />
      </div>
    )
  }
}
export default Graph
